@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.bar {
    display: flex;
    background: $colors-yellow7;
}

.barHoverAndFocus {
    background: $colors-yellow8;
}

.yes {
    display: flex;
    align-items: center;
    height: 50px;
    background: $colors-green3;
    color: $colors-white;
    font-weight: 400;
}

.yesHoverAndFocus {
    background: $colors-green6;
}

.zeroYes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 1rem;
    color: black;
    font-weight: 400;
}

.circleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    height: 9px;
    width: 9px;
    border-radius: 50%;
}