@import '../../theme/colors';
@import '../../theme/typography';
@import '../../theme/breakpoints';

.content {
  padding: 20px 50px;
  padding-bottom: 100px;

  .breadCrumbsContainer {
    margin-top: -50px;
  }

  .coloredText {
    color: $colors-blue2;
    margin-top: 5px;
    font-weight: 400;
    letter-spacing: 3px;
    font-size: 29px;
    text-transform: uppercase;
    margin-bottom: -10px;
  }

  .subHeading {
    color: rgb(226, 63, 91);
    margin: 20px 0;
  }

  .colorGrid {
    display: grid;
    grid-template-columns: 1fr;

    @include bp-min-lg {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .colorItem {
    margin: 10px;

    h2 {
      padding-left: 20px;
      font-size: 18px;
    }

    div {
      height: 100px;
    }
  }
}