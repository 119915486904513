@import 'src/theme/colors';
@import 'src/theme/breakpoints';
@import 'src/theme/typography';

.heading {
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid;
}

.divContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.deleteModalTitle {
  margin: 0;
}

.deleteModalDescription {
  max-width: 420px;
}

.formSection {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 20px;
}

.label {
  display: flex;
  justify-content: flex-start;
  text-align: right;
  margin-right: 1rem;
}

.modalBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    margin: 0 0.5rem;
  }
}

.formContainer {
  padding: 15px 5px;
  height: 500px;
  overflow-y: scroll;

  @include bp-min-lg {
    padding: 15px;
    height: 800px;
  }

  form {
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  svg {
    margin: 0;
    padding: 0;
  }
}
