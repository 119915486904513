@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.stateGapAnalysisSection {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    background-color: $colors-light-gray5;
    line-height: 26px;
}

.contentTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.5rem;
    font-weight: 600;

    @include bp-min-lg {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

.policyName {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 1rem;
    color: $colors-dark-gray2;
    font-weight: 400;
    font-size: 1.3rem;

    @include bp-min-sm {
        font-size: 1.5rem;
    }

    @include bp-min-lg {
        margin-top: 0;
    }
}

.hyphen {
    display: none;

    @include bp-min-lg {
        display: initial;
    }
}

.contentBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(50, 1fr);
    grid-auto-flow: column;
    justify-items: center;
    align-items: flex-start;
    margin-bottom: 2rem;

    @include bp-min-lg {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(25, 1fr);
        padding: 0 3rem;
    }

    @media (min-width: 1250px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(17, 1fr);
    }
}

.stateItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto;
    width: 175px;
    margin-top: 5rem;
    color: $colors-blue6;
    text-decoration: none;

    @include bp-min-xs {
        height: 150px;
        width: 220px;
        margin-top: 0;
    }

    @include bp-min-sm {
        height: 135px;
        width: 255px;
    }

    &:hover {
        background: $colors-light-gray8;
        color: $colors-black;
        font-weight: 600;
        cursor: pointer;
    }

    &:focus {
        background: $colors-light-gray8;
        color: $colors-black;
        font-weight: 600;
        cursor: pointer;
    }
}

.stateName {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    height: 40px;
}