@import '../../theme/colors';
@import '../../theme/breakpoints';

.summaryContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;

  @include bp-min-md {
    font-size: 1.2rem;
  }

  @include bp-min-lg {
    font-size: 1.4rem;
  }
}
