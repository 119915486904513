@import '../../theme/colors';
@import '../../theme/breakpoints';

.navContainer {
  background-color: $colors-black;
  margin: 0;
  height: 38px;
  display: flex;

  @include bp-min-lg {
    text-align: right;
    @include site-horizontal-padding;
  }

  .textContainer {
    color: $colors-white;
    max-width: $site-max-width;
    margin: 0 auto;
    padding-left: 10px;
    font-size: 0.63rem;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    @include bp-min-xs {
      font-size: 0.7rem;
    }

    @include bp-min-sm {
      font-size: 0.8rem;
    }

    @include bp-min-base {
      text-align: center;
      padding-left: 0px;
      justify-content: center;
    }

    @include bp-min-lg {
      justify-content: flex-end;
      font-size: 1rem;
    }
  }

  strong {
    color: $colors-yellow1;
    font-size: 0.63rem;
    white-space: nowrap;

    @include bp-min-xs {
      font-size: 0.7rem;
    }

    @include bp-min-sm {
      font-size: 0.8rem;
    }

    @include bp-min-lg {
      font-size: 1rem;
    }

    &:hover {
      color: $colors-yellow4;
      text-decoration: underline;
    }
  }
}

.hamburgerContainer {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colors-black;
  font-size: 2rem;
  z-index: 3;

  img {
    width: 46.4px;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: $colors-blue6 solid 4px;
  }
}

.authenticated {
  position: absolute;
  color: white;
  top: 8px;
}
