@import '../../theme/colors';
@import '../../theme/typography';
@import '../../theme/breakpoints';

.buttonMuiOverride {
  &:hover {
    .arrow {
      visibility: visible;
    }
  }

  &:focus {
    .arrow {
      visibility: visible;
    }
  }
}

.textIconContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-right: 0.5rem;
    font-size: 1rem;
    font-weight: 400;

    @include bp-min-xs {
      width: inherit;
    }

    @include bp-min-sm {
      width: 280px;
      margin-right: 0;
    }

    @include bp-min-md {
      width: 130px;
      font-size: 0.85rem;
      margin-right: 0.5rem;
    }

    @include bp-min-lg {
      width: 250px;
      font-size: 1.1rem;
    }

    @include bp-min-xl {
      width: 350px;
      font-size: 1.25rem;
    }
  }

  img {
    width: 30px;

    @include bp-min-sm {
      width: 40px;
    }

    @include bp-min-md {
      width: 25px;
    }

    @include bp-min-lg {
      width: initial;
      margin-right: 0.5rem;
    }
  }
}

.arrow {
  visibility: hidden;
  position: absolute;
  color: $colors-white;
  background-color: $colors-black;
  padding: 0;
  padding: 4px 5.5px;
  border-radius: 50%;
  border: 1.5px solid $colors-white;
  font-size: inherit;
  right: -12px;
  top: 42%;
}
