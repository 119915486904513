@import '../../theme/colors';
@import '../../theme/breakpoints';

.generalLink {
  font-family: 'Lato';
  color: $colors-blue1;
  font-size: 1.13rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }
}