@import '../../theme/colors';
@import '../../theme/breakpoints';

.typeAccordion {
  border: solid $colors-med-gray1 1px;
  margin: 1rem 0;
}

.detailContent {
  font-size: 0.8rem;
  line-height: 18px;
  margin: 0;
  margin-bottom: 10px;
  border-bottom: solid $colors-med-gray1 1px;

  @include bp-min-md {
    font-size: 1.15rem;
    line-height: 27px;
    padding-bottom: 18px;
    margin: 0 5rem;
    margin-bottom: 30px;
  }

  @include bp-min-lg {
    font-size: 1.25rem;
  }
}

.detailContent:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.policyComponentQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;

  @include bp-min-lg {
    flex-direction: row;
  }
}

.questionText {
  flex: 70%;
}

.radioForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;

  @include bp-min-xs {
    flex-direction: row;
    width: 210px;
  }

  @include bp-min-lg {
    margin-top: -0.5rem;
    margin-left: 2rem;
  }
}

.policyLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 0;
  margin: 1rem 0;

  @include bp-min-lg {
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 3rem;
  }
}

.policyLinkLabel {
  flex: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-bottom: 1rem;

  @include bp-min-lg {
    margin-right: 2rem;
  }
}
