@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.aboutContent a {
  color: $colors-blue1;
  text-decoration: underline;
  text-underline-offset: 3.5px;

  &:hover {
    text-decoration: none;
  }

  &:focus:active {
    text-decoration: underline overline;
  }
}