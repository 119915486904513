@import '../../theme/colors';
@import '../../theme/breakpoints';

.iconContainer,
.iconOpen {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: 5px;
  margin-top: -21px;
  padding: 10px 0px;

  img {
    width: 20px;
  }
}

.labelLeft {
  display: flex;
  gap: 20px;
  
  &.MuiInputLabel-root {
    justify-content: flex-end;
    text-align: right;
    width: 30%;
    margin-right: 1rem;
  }
}