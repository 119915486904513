@import '../../theme/colors';
@import '../../theme/breakpoints';

.policyProfileSummarySection {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 2rem 0;
  border-radius: 8px;
  background-color: $colors-light-gray5;
  line-height: 26px;
}

.contentTitle {
  padding: 2rem;
  padding-bottom: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.contentBlock {
  padding: 0 2rem;
  margin: 1rem 0;
  font-size: 1.375rem;
  line-height: 34px;
}

.mainAccordion {
  background: transparent;
  width: 100%;
  padding: 1rem 0.5rem;
}

.summaryContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  color: $colors-blue6;
  font-size: 1.4rem;
}

.briefDownloadLink {
  color: $colors-blue1;
  text-decoration: none;
}

.noFileText {
  font-weight: bold;
}

.mainAccordionDetails {
  margin: 0;
  padding-top: 0;

  @include bp-min-md {
    padding: 0 2rem;
  }
}

.question {
  padding: 0 2rem;
  margin: 0.5rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 34px
}