@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.mainAccordion {
  border: solid $colors-med-gray1 2px;
  border-bottom: none;
}

.mainAccordion:last-child {
  border-bottom: solid $colors-med-gray1 2px;
}

.summaryContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;

  @include bp-min-md {
    font-size: 1.2rem;
  }

  @include bp-min-lg {
    font-size: 1.4rem;
  }
}

.mainAccordionDetails {
  margin: 0;
  padding: 1rem;
  padding-top: 0;

  @include bp-min-md {
    margin: 1rem 5rem;
  }
}

.subAccordion {
  margin: 1rem 0;
}

.subAccordion:last-child {
  margin-bottom: 0;
}

.categoryBtnContainer {
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-right: 2.5rem;
  margin-top: 1.5rem;
  width: 70%;

  @include bp-min-lg {
    justify-content: flex-end;
    width: 50%;
    margin-top: 0;
  }

  button {
    margin: 0 0.5rem;
  }
}

.subcategoryBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  width: 70%;

  @include bp-min-lg {
    justify-content: flex-end;
    width: 50%;
    margin-top: 0;
  }

  button {
    margin: 0 0.5rem;
  }
}
