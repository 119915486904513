@import '../../theme/colors';
@import '../../theme/breakpoints';

.disclaimerWrapper {
  margin: 0 auto 50px auto;
  @include site-horizontal-padding;
}

.disclaimerSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.description {
  font-size: 12px;
}