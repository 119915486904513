@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.section {
  padding: 0 2rem;
  width: 100%;
}

.label {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.selectContainer {
  width: 100%;
  margin-top: 1rem;

  @include bp-min-md {
    width: 60%;
  }

  @include bp-min-lg {
    width: 40%;
  }

  @include bp-min-xl {
    width: 30%;
  }
}

.policyBriefPreview {
  display: flex;
  flex-direction: column;
}

.previewHeader {
  font-size: 1.2rem;
  font-weight: bold;
}

.previewHelperText {
  font-size: .95rem;
  font-style: italic;
  color: $colors-dark-gray1;
}

.policyBriefContainer {
  margin: 5px 0;
  min-height: 100px;
  border: 2px $colors-med-gray1 dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
