@import '../../theme/colors';
@import '../../theme/breakpoints';

.policyProfileSection {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp-min-lg {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.headingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.policySelectorLabel {
  font-weight: normal;
  margin: 0;
}

.policyComparisonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr;
  gap: 2px 2px;
  grid-template-areas:
    'topRight topRight topRight .'
    'stateSelector1 stateSelector2 stateSelector3 stateSelector4'
    'number1 question1 question1 question1'
    '. state1Q1 state1Q1 state1Q1'
    '. state2Q1 state2Q1 state2Q1'
    '. state3Q1 state3Q1 state3Q1'
    '. state4Q1 state4Q1 state4Q1'
    '. . . .'
    'number2 question2 question2 question2'
    '. state1Q2 state1Q2 state1Q2'
    '. state2Q2 state2Q2 state2Q2'
    '. state3Q2 state3Q2 state3Q2'
    '. state4Q2 state4Q2 state4Q2'
    '. . . .'
    'number3 question3 question3 question3'
    '. state1Q3 state1Q3 state1Q3'
    '. state2Q3 state2Q3 state2Q3'
    '. state3Q3 state3Q3 state3Q3'
    '. state4Q3 state4Q3 state4Q3'
    '. . . .'
    'number4 question4 question4 question4'
    '. state1Q4 state1Q4 state1Q4'
    '. state2Q4 state2Q4 state2Q4'
    '. state3Q4 state3Q4 state3Q4'
    '. state4Q4 state4Q4 state4Q4'
    '. . . .'
    'number5 question5 question5 question5'
    '. state1Q5 state1Q5 state1Q5'
    '. state2Q5 state2Q5 state2Q5'
    '. state3Q5 state3Q5 state3Q5'
    '. state4Q5 state4Q5 state4Q5'
    '. . . .'
    'number6 question6 question6 question6'
    '. state1Q6 state1Q6 state1Q6'
    '. state2Q6 state2Q6 state2Q6'
    '. state3Q6 state3Q6 state3Q6'
    '. state4Q6 state4Q6 state4Q6';

  @include bp-min-lg {
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.5fr 0.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr;
    gap: 4px 4px;
    grid-template-areas:
      'topLeft topLeft topRight topRight topRight topRight'
      '. . stateSelector1 stateSelector2 stateSelector3 stateSelector4'
      'number1 question1 state1Q1 state2Q1 state3Q1 state4Q1'
      'number2 question2 state1Q2 state2Q2 state3Q2 state4Q2'
      'number3 question3 state1Q3 state2Q3 state3Q3 state4Q3'
      'number4 question4 state1Q4 state2Q4 state3Q4 state4Q4'
      'number5 question5 state1Q5 state2Q5 state3Q5 state4Q5'
      'number6 question6 state1Q6 state2Q6 state3Q6 state4Q6'
      '. . stateBtn1 stateBtn2 stateBtn3 stateBtn4';
  }
}
.topLeft {
  display: none;
  grid-area: topLeft;
  font-size: 1.5rem;
  margin: 0;

  @include bp-min-lg {
    display: block;
  }
}
.topRight {
  grid-area: topRight;
  font-size: 1.5rem;
  margin: 0;
}

.answerContainer {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.answerStateText {
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize;

  @include bp-min-md {
    display: none;
  }
}

.notApp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 58px;
  background: $colors-light-gray2;
  font-weight: 300;
}

.yes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 58px;
  background: $colors-green3;
  color: $colors-white;
  font-size: 1.75rem;
  font-weight: bold;
}

.no {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 58px;
  background: $colors-yellow7;
  font-size: 1.75rem;
  font-weight: bold;
}

#billButton {
  display: none;
  font-size: 0.8rem;
  white-space: nowrap;

  @include bp-min-lg {
    display: block;
  }
}
