@import '../../theme/colors';
@import '../../theme/breakpoints';

.mainNavContainer {
  position: relative;
  z-index: 1;
  height: 60px;
  background: linear-gradient(#0F5874 10%,#0B9AD2 );


  @include bp-min-lg {
    height: 87px;
    @include site-horizontal-padding;
  }
}

.innerContent {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  max-width: $site-max-width;
}

.navLinksContainer {
  color: $colors-white;
  font-size: 1.2rem;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.firstLayerContainer {
  display: flex;
  flex-direction: column;
}