@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.section {
    padding: 4rem 0;
    border-bottom: solid $colors-med-gray1 1px;
}

.sectionTitle {
    margin-bottom: 2rem;
    font-weight: bold;
}

.label {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 30%;
    margin-right: 1rem;
}

.groupedFieldLabel {
    margin-left: 0;

    @include bp-min-lg {
        margin-left: 1rem;
    }
}

.groupedFields {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    @include bp-min-lg {
        flex-direction: row;
        margin-top: 2rem;
    }
}

.selectLabel {
    margin-top: 1rem;
}