@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.pageContainer {
  margin-top: 3.5rem;
}

.buttonsContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding: 2rem;
  margin: 2rem 0;
  background: $colors-light-gray3;
  border-radius: 9px;

  @include bp-min-lg {
    align-items: center;
    flex-direction: row;
    height: 180px;
  }
}

.addCategoryBtnContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 70%;

  @include bp-min-lg {
    justify-content: flex-start;
    width: 50%;
    padding-left: 0.5rem;
    margin-bottom: 0;
  }
}

.cancelSaveBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  width: 70%;

  @include bp-min-lg {
    justify-content: flex-end;
    width: 50%;
    margin-top: 0;
  }

  button {
    margin: 0 0.5rem;
  }
}
