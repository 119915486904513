@import '../../theme/colors';
@import '../../theme/breakpoints';

.home {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include bp-min-lg {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.headingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  @include bp-min-base {
    margin-bottom: 0;
  }

  @include bp-min-lg {
    width: 60%;
  }
}

.selectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  gap: 0.5rem;

  @include bp-min-lg {
    justify-content: flex-end;
    width: 40%;
    margin-bottom: 0;
  }
}
