@mixin bp-min-xs {
  @media (min-width: 360px) {
    @content;
  }
}

@mixin bp-min-sm {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin bp-min-base {
  @media (min-width: 624px) {
    @content;
  }
}

@mixin bp-min-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin bp-min-lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin bp-min-xl {
  @media (min-width: 1450px) {
    @content;
  }
}

$site-max-width: 1450px;
$content-padding: 0px 50px;

@mixin site-horizontal-padding {
  padding-left: 20px;
  padding-right: 20px;

  @include bp-min-base {
    padding-left: 50px;
    padding-right: 50px;
  }
}