@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form {
  display: flex;
  gap: 15px;
  flex-direction: column;
  background-color: $colors-light-gray5;
  padding: 20px 15px;
  border-radius: 5px;

  @include bp-min-lg {
    padding: 30px;
  }

  p {
    margin: 1rem 0;
    letter-spacing: 0;
    line-height: 25px;
  }
}

.newAccountLink {
  font-size: 1rem;
}

.inputContainer {
  width: 90%;
}

.buttonContainer {
  margin: 1rem 0 1.5rem 0;
}
