@import '../../theme/typography';
@import '../../theme/breakpoints';
@import '../../theme/colors';

.headingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  @include bp-min-base {
    margin-bottom: 0;
  }

  @include bp-min-lg {
    width: 60%;
  }
}