@import '../../theme/colors';
@import '../../theme/breakpoints';

.policyProfileSection {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
}

.headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include bp-min-lg {
        flex-direction: row;
        margin-bottom: 0;
    }
}

.headingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;

    @include bp-min-base {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
    }
}

.subHeading {
    margin-top: -1.5rem;

    @include bp-min-base {
        margin-top: -0.5rem;
    }

    @include bp-min-lg {
        margin-top: 0;
    }
}

.line {
    display: none;

    @include bp-min-base {
        display: initial;
        margin-left: 15px;
        font-size: 1.563rem;
    }
}

.gapAnalysisLink {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-top: 0.7rem;
    color: $colors-blue1;
    font-size: 1.25rem;
    text-decoration: none;

    @include bp-min-base {
        padding-left: 15px;
        margin-top: 5px;
    }

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
    }
}

.selectContainer {
    margin-top: 2rem;
    width: 100%;

    @include bp-min-lg {
        margin-top: 0;
        width: 50%;
    }
}