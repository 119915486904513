@import '../../theme/colors';
@import '../../theme/breakpoints';

.policyComponentQuestionsSection {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.gapAnalysisBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem 0 1rem;
  border-radius: 8px 8px 0 0;
  background: $colors-light-gray5;

  @include bp-min-sm {
    width: 70%;
  }

  @include bp-min-lg {
    width: 40%;
  }
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  padding-bottom: 3rem;
  border-radius: 0 8px 8px 8px;
  background: $colors-light-gray5;
}

.emphasized {
  font-weight: bold;
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  vertical-align: baseline;
  margin: 2rem 0;
  padding: 0 1rem;
  line-height: 34px;

  @include bp-min-md {
    flex-direction: row;
    margin: 1.25rem 0;
  }
}

.question a {
  display: inline;
  color: $colors-blue6;
  text-underline-offset: 3.5px;
  font-size: 1.25rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
}

.numberAndStatusContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  @include bp-min-md {
    width: auto;
    margin-bottom: 0;
  }
}

.number {
  display: flex;
  color: $colors-dark-gray2;
  margin-right: 1.5rem;
  font-size: 3.438rem;

  @include bp-min-md {
    margin-right: 1rem;
  }
}

.grayedNumber {
  display: flex;
  color: $colors-dark-gray2;
  margin-right: 1.5rem;
  font-size: 3.438rem;

  @include bp-min-md {
    margin-right: 1rem;
  }
}

.notApp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 40px;
  margin-right: 0;
  background: $colors-light-gray2;
  font-weight: 300;

  @include bp-min-md {
    margin: 0 1rem;
  }
}

.yes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 40px;
  margin-right: 0;
  background: $colors-green3;
  color: $colors-white;
  font-size: 1.25rem;
  font-weight: bold;

  @include bp-min-md {
    margin: 0 1rem;
  }
}

.no {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 40px;
  margin-right: 0;
  background: $colors-yellow7;
  font-size: 1.25rem;
  font-weight: bold;

  @include bp-min-md {
    margin: 0 1rem;
  }
}

.questionTextContainer {
  display: flex;
  flex-direction: column;
}

.questionText {
  display: inline;
  margin-left: 0;
  font-size: 1.44rem;

  @include bp-min-md {
    margin-left: 0.5rem;
  }
}

.line {
  display: none;

  @include bp-min-sm {
    display: initial;
    margin: 0 1rem;
  }
}
