@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.summaryContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 0.9rem;

  @include bp-min-md {
    font-size: 1.2rem;
  }

  @include bp-min-lg {
    font-size: 1.4rem;
  }
}

.detailContent {
  font-size: 0.8rem;
  line-height: 18px;
  padding-bottom: 10px;

  @include bp-min-md {
    font-size: 1.15rem;
    line-height: 27px;
    padding-bottom: 18px;
  }

  @include bp-min-lg {
    font-size: 1.25rem;
  }
}