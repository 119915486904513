@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.pageContainer {
  margin-top: 3.5rem;
}

.selectContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 220px;
  padding: 2rem;
  margin: 2rem 0;
  background: $colors-light-gray3;
  border-radius: 9px;
}

.dropDownSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 30%;
}

.viewing {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: left;
}

.stateSelectContainer {
  width: 100%;
}

.stateSnapshotMgmtContent {
  margin: 4rem 0;
}

.statePolicyMgmtContent {
  margin: 2rem 0;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.buttonColumnWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
