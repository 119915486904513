@import '../../theme/colors';
@import '../../theme/breakpoints';

.policyLinksSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include bp-min-base {
    align-items: flex-start;
  }
}

.policyLinksContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include bp-min-base {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
}

.otherLinksContent {
  text-align: center;
  @include bp-min-base {
    text-align: left;
    width: 40%
  }
}