@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import './colors';
@import './breakpoints.scss';

body {
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  color: $colors-dark-gray1;
}

p:last-child {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
