@import '../../theme/colors';
@import '../../theme/breakpoints';

.generalLink {
  font-family: 'Lato';
  color: $colors-blue1;
  font-size: 23px;
  margin: 0;
  padding: 0;
  line-height: 38px;
  cursor: pointer;

  &:hover {
    color: $colors-blue2;
  }
}