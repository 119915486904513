@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.uploadButton {
  font-size: 15px;
  background-color: #4a4a4a;
  border-radius: 5px;
  color: $colors-white;
  border: none;
  padding: 15px 17px;
  &:hover {
    background-color: $colors-dark-gray1;
    cursor: pointer;
  }
  &:focus {
      outline: 4px double $colors-black;
      background: 'none';
      background-color: $colors-black;
      color: $colors-white;
      text-decoration: 'underline' ;

  }
}