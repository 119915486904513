@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.briefDownloadLink {
  color: $colors-blue6;
  text-decoration: none;
}

.noFileText {
  font-weight: bold;
}