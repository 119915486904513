@import '../../theme/colors';
@import '../../theme/breakpoints.scss';

.heading1 {
  font-size: 1.5rem;
  font-weight: 500;
  -webkit-text-stroke: 1px $colors-dark-gray1;
  letter-spacing: 2px;

  @include bp-min-md {
    font-size: 1.6rem;
  }

  @include bp-min-lg {
    font-size: 1.875rem;
  }
}

.heading2 {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.5px;
  -webkit-text-stroke: 1px $colors-dark-gray1;
  padding-top: 20px;
  padding-bottom: 20px;

  @include bp-min-md {
    font-size: 1.25rem;
    line-height: 30px;
  }

  @include bp-min-xl {
    font-size: 1.25rem;
  }
}

.heading3 {
  font-size: 1.62rem;
  font-weight: 600;
  padding: 28px 0px;
}

.heading4 {
  margin: 0;
  padding: 0;
  font-size: 1.44rem;
  font-weight: 400;
  line-height: 38px;
  padding-bottom: 30px;
  color: $colors-dark-gray1;
}