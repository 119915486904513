@import '../../theme/colors';
@import '../../theme/breakpoints';

.contentBlock {
    margin: 2rem 0;

    p {
      margin: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
}

.emphasized {
    font-weight: 600;
}

.links {
    color: $colors-blue6;
    text-decoration: none;
    font-weight: 400;

    &:hover {
        text-decoration: underline;
    }

    a:hover,
    a:focus {
        color: $colors-black;
    }
}
