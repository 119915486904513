@import '../../theme/colors';
@import '../../theme/breakpoints';

.state {
    outline: none;

    &:hover,
    &:focus-visible {
        filter: brightness(100%);
    }

    @include bp-min-sm {

        &:hover,
        &:focus-visible {
            cursor: pointer;
            filter: brightness(85%);
            outline: none;
            transition: 60ms ease;
        }
    }
}

svg {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 5px;
    margin-top: -10px;

    @include bp-min-lg {
        padding: 0px 20px;
    }
}