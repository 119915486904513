// ACHROMATIC
$colors-black: #000000;
$colors-black-transparent: rgba(0, 0, 0, 0.85);
$colors-black-transparent2: rgba(0, 0, 0, 0.6);
$colors-white: #ffffff;
$colors-dark-gray1: #333333;
$colors-dark-gray2: #666666;
$colors-dark-gray3: #242424;
$colors-med-gray1: #999999;
$colors-light-gray1: #f4f4f4;
$colors-light-gray2: #e4e4e4;
$colors-light-gray3: #e8e8e8;
$colors-light-gray4: #cccccc;
$colors-light-gray5: #f6f6f6;
$colors-light-gray6: #fcfafa;
$colors-light-gray7: #f3f3f3;
$colors-light-gray8: #d8d8d8;
$colors-light-gray9: #dddddd;
$colors-light-gray10: #fafafa;

//CHROMATIC
$colors-blue1: #1a78be;
$colors-blue2: #065694;
$colors-blue3: #0b9ad2;
$colors-blue4: #0f5874;
$colors-blue5: #00678f;
$colors-blue6: #1465a1;
$colors-green1: #09812f;
$colors-green2: #61a739;
$colors-green3: #487D26;
$colors-green4: #5c8427;
$colors-green5: #7d9a59;
$colors-green6: #8cc63f;
$colors-green7: #105225;
$colors-green8: #356200;
$colors-green9: #267b2b;
$colors-green10: #5ea538;
$colors-orange1: #ffa100;
$colors-orange2: #d8a102;
$colors-orange3: #E6901A;
$colors-yellow1: #ffcb70;
$colors-yellow2: #ebd36a24;
$colors-yellow3: #fdd694;
$colors-yellow4: rgba(255, 224, 170, 0.98);
$colors-yellow5: #ebd36a;
$colors-yellow6: #cc983c;
$colors-yellow7: #fecb08;
$colors-yellow8: #ffe1a7;
$colors-focus: rgba(219, 59, 86, 0.73);