@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.faqHeading {
  text-align: center;
  margin: 20px 0px;

  @include bp-min-md {
    text-align: left;
  }
}

.faqContent {
  background-color: $colors-light-gray1;
}

.faqContent a {
  color: $colors-blue1;
  text-decoration: underline;
  text-underline-offset: 3.5px;

  &:hover {
    text-decoration: none;
  }

  &:focus:active {
    text-decoration: underline overline;
  }
}