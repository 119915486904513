@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.gapAnalysis {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    background-color: $colors-light-gray5;
    line-height: 26px;
    font-size: 1.125rem;
}

.contentTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.contentBlock {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: flex-start;
    height: fit-content;
    width: 100%;
    margin: 1rem 0;

    @include bp-min-lg {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1250px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: 3rem;
    }

}

.emphasized {
    font-weight: 600;
}

.firstColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    max-width: 280px;
    margin-top: 3rem;
    margin-left: -0.5rem;
    padding: 0;
    border-left: none;

    @include bp-min-xs {
        padding: 0 1rem;
        margin-left: 0;
        border-left: solid $colors-blue6 4px;
    }

    @include bp-min-sm {
        padding: 0 2rem;
    }

    @include bp-min-lg {
        margin: 0 3.375rem;
    }
}

.secondColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    max-width: 280px;
    margin-top: 5rem;
    padding: 0;
    border-left: none;

    @include bp-min-xs {
        padding: 0 1rem;
        margin-top: 3rem;
        border-left: solid $colors-blue6 4px;
    }

    @include bp-min-sm {
        padding: 0 2rem;
    }

    @include bp-min-lg {
        margin: 0 3.375rem;
    }
}

.thirdColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    max-width: 280px;
    margin-top: 5rem;
    padding: 0;
    border-left: none;

    @include bp-min-xs {
        padding: 0 1rem;
        margin-top: 3rem;
        border-left: solid $colors-blue6 4px;
    }

    @include bp-min-sm {
        padding: 0 2rem;
    }

    @include bp-min-lg {
        margin: 0 3.375rem;
        margin-top: 3rem;
    }

    @media (min-width: 1250px) {
        margin-top: 0;
    }
}

.columnHeading {
    display: flex;
    height: auto;

    @include bp-min-xs {
        width: 220px;
    }

    @include bp-min-sm {
        width: 255px;
    }
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.75rem;
    font-weight: 300;
}

.columnHeadingText {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 1.25rem;
}

.emphasizedHeading {
    font-size: 1.563rem;
    font-weight: 600;
}

.policyItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto;
    width: 175px;
    margin-top: 5rem;
    color: $colors-blue6;
    text-decoration: none;

    @include bp-min-xs {
        height: 150px;
        width: 220px;
        margin-top: 0;
    }

    @include bp-min-sm {
        height: 135px;
        width: 255px;
    }

    &:hover {
        background: $colors-light-gray8;
        color: black;
        font-weight: 600;
        cursor: pointer;
    }

    &:focus {
        background: $colors-light-gray8;
        color: black;
        font-weight: 600;
        cursor: pointer;
    }
}

.policyName {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    height: 40px;
}