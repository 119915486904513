@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.mainAccordion {
  border: solid $colors-med-gray1 2px;
  border-bottom: none;
}

.mainAccordion:last-child {
  border-bottom: solid $colors-med-gray1 2px;
}

.summaryContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;

  @include bp-min-md {
    font-size: 1.2rem;
  }

  @include bp-min-lg {
    font-size: 1.4rem;
  }
}

.lastEditedBy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  font-size: 0.8rem;
  font-style: italic;

  @include bp-min-md {
    font-size: 0.9rem;
  }

  @include bp-min-lg {
    width: 100%;
    margin-right: 1rem;
    font-size: 1rem;
  }
}

.mainAccordionDetails {
  margin: 0;
  padding: 1rem;
  padding-top: 0;

  @include bp-min-md {
    margin: 1rem 5rem;
  }
}
