@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.mapContainer {
  width: 100%;
  margin: 2rem 0;

  @include bp-min-base {
    margin: 0;
  }
}

.toolTip {
  position: relative;
  background-color: $colors-white !important;
  color: $colors-dark-gray3 !important;
  opacity: 1 !important;
}
