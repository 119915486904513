@import '../../theme/colors';
@import '../../theme/breakpoints';

.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp-min-lg {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.headingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  @include bp-min-base {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
}

.selectLine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;

  @include bp-min-lg {
    flex-direction: row;
    margin-top: 4rem;
  }
}

.stateSelectContainer {
  width: 90%;
  margin: 1rem 0;
  text-transform: capitalize;

  @include bp-min-sm {
    width: 50%;
  }

  @include bp-min-lg {
    width: 300px;
    margin: -1.7rem 1rem 0 1rem;
  }
}

.policySelectContainer {
  width: 90%;
  margin: 1rem 0;

  @include bp-min-lg {
    width: 700px;
    margin: -1.7rem 1rem 0 1rem;
  }
}

.buttonPositionDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

#billButton {
  font-size: 1.1rem;
  padding: 20px 90px;
  margin: 0 auto;

  @include bp-min-base {
    padding: 20px 125px;
  }

  @include bp-min-lg {
    margin-top: 0;
    padding: 25px 70px;
  }
}

.stateName {
  font-size: 1.5rem;
  font-weight: 500;
  -webkit-text-stroke: 1px $colors-dark-gray1;
  letter-spacing: 2px;
  text-transform: capitalize;

  @include bp-min-md {
    font-size: 1.6rem;
  }

  @include bp-min-lg {
    font-size: 1.875rem;
  }
}

@media print {
  .print {
    margin: 0px 40px;
  }
}

.moreInformation {
  color: #242424;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 1rem;
  text-transform: uppercase;
  // How is the font not Lato everywhere?
  font-family: 'Lato', sans-serif;
}
