@import '../../theme/colors';
@import '../../theme/breakpoints';

.pageContainer {
  margin-top: 3.5rem;
}

.settingContent {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 2rem 0;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background: $colors-light-gray3;
  border-radius: 9px;
  gap: 2rem;

  @include bp-min-lg {
    padding: 2rem;
  }
}

.settingTitle {
  margin: 0;
  font-size: 1.44rem;
  font-weight: 400;
  line-height: 38px;
  color: $colors-dark-gray1;
}

.submitButton {
  max-width: 120px;
}
