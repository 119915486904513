@import '../../theme/colors';
@import '../../theme/breakpoints';

.link {
  text-align: center;
  font-size: 1rem;
  color: $colors-blue1;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;

  @include bp-min-md {
    text-align: left;
    line-height: 38px;
    font-size: 23px;
  }

  &:hover {
    color: $colors-blue2;
    text-decoration: underline;
  }
}

.linkSpacer {
  height: 23px;
  border: 1px solid $colors-black;
}
