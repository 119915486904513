@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';



.sectionTitle {
    margin-bottom: 2rem;
    font-weight: bold;
}

.form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.textfields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin: 1rem 0;

    @include bp-min-md {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

.label {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 20%;
    margin-right: 1rem;

    @include bp-min-md {
        width: initial;
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    @include bp-min-md {
        justify-content: flex-start;
        padding-left: 20%;
    }

    @include bp-min-lg {
        padding-left: 15%;
    }
}

.deleteButton {
    background: none;
    border: none;

    &:focus {
        .deleteIcon {
            color: $colors-black;
        }
    }
}

.deleteIcon {
    margin-top: 3px;
    font-size: 1.5rem;
    color: $colors-blue6;
    cursor: pointer;

    @include bp-min-md {
        font-size: 2rem;
    }

    &:hover {
        color: $colors-black;
    }
}