@import '../../theme/colors';
@import '../../theme/breakpoints';

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  @include bp-min-md {
    flex-direction: row;
  }
}

.stateComparison {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  border-radius: 8px;
  background-color: $colors-light-gray5;
  font-size: 1.125rem;

  @include bp-min-sm {
    flex-direction: row;
  }

  @include bp-min-md {
    margin-bottom: 0;
  }

  @include bp-min-lg {
    margin-right: 2rem;
  }

  b {
    font-size: 1.5rem;
    margin-bottom: 1rem;

    @include bp-min-sm {
      margin-bottom: 0;
    }
  }

  .line {
    display: none;

    @include bp-min-sm {
      display: flex;
      margin: 0 1rem;
      font-size: 1.5rem;
    }
  }
}
