@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.upperNavLink {
  li {
    &:focus-visible {
      outline: none;
      border: none;
    }
  }

  img {
    margin-left: 7px;
    width: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

// Admin dropdown styling override
.upperNavLink:last-child>ul>li>ul {
  margin-left: 35px;
}

.nav a {
  display: block;
  color: #fff;
  text-decoration: none !important;
  padding: 14px 0px;
  padding-left: 15px;
  font-family: 'Lato';
  position: relative;
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}

.nav {
  vertical-align: top;
  display: inline-block;
  border-radius: 6px;
}

.nav li {
  position: relative;
}

.nav>li {
  float: left;
  margin-right: 1px;
}

.nav>li>a {
  padding: 0px;
  height: 87px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: 40px;

  @include bp-min-xl {
    width: initial;
  }
}

.nav li:hover>a,
.nav li:active>a {
  filter: brightness(0) saturate(100%) invert(75%) sepia(59%) saturate(392%) hue-rotate(343deg) brightness(102%) contrast(101%);
  color: $colors-yellow1;
}

.nav li:focus-visible>a {
  filter: brightness(0) saturate(100%) invert(75%) sepia(59%) saturate(392%) hue-rotate(343deg) brightness(102%) contrast(101%);
  color: $colors-yellow1;
}

.nav ul {
  position: absolute;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;
  min-width: 88.5%;
  left: -99999em;
  margin-left: 70px;

  li {
    a {
      font-size: 1rem;
      font-weight: 400;
      padding: 13px 15px;
    }

    &:hover,
    &:focus {
      background-color: $colors-yellow1;

      a {
        color: $colors-dark-gray1;
        font-weight: bold;
        filter: none;
      }
    }
  }
}

.nav>li {

  &:hover>ul,
  &:focus-within>ul {
    left: auto;
    visibility: visible;
  }
}

.nav>li li ul {
  background-color: $colors-yellow4;
  margin-left: 0px;
  margin-top: 1px;
  border: none;
  width: 70%;

  li {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;

    a {
      padding: 14px 15px;
      color: $colors-black;
      font-weight: 700;
      font-size: 0.9rem;
    }
  }
}

.nav>li {
  li {

    &:hover>ul,
    &:focus-within>ul {
      left: 100%;
      top: -1px;
    }
  }
}

.goldLine {
  height: 5px;
  background-color: $colors-yellow1;
  width: 80%;
  margin-top: -15px;
  margin-bottom: 10px;
}

#menuHidden {
  visibility: hidden;
}