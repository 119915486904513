@import '../../theme/breakpoints';

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: 300;

  @include bp-min-md {
    flex-direction: row;
  }
}

.legendStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include bp-min-sm {
    flex-direction: row;
  }

  @include bp-min-md {
    justify-content: flex-end;
    width: 50%;
  }
}

.legendStatus > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @include bp-min-sm {
    margin-bottom: 0;
  }
}

.legendLastUpdated {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
  width: 100%;

  @include bp-min-xs {
    width: 80%;
  }

  @include bp-min-sm {
    justify-content: center;
    text-align: left;
    margin-top: 0.5rem;
    width: 90%;
  }

  @include bp-min-md {
    justify-content: flex-start;
    width: 50%;
    margin-top: 0;
  }
}

.line {
  display: none;

  @include bp-min-sm {
    display: initial;
    margin: 0 1rem;
  }
}
